import { BarControllerChartOptions } from 'chart.js';
import { ReactNode, CSSProperties } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';

export enum UserRole {
  user = 'ROLE_USER',
  buyer = 'ROLE_BUYER',
  superAdmin = 'ROLE_SUPER_ADMIN',
  auctioneerAdmin = 'ROLE_AUCTIONEER_ADMIN',
  auctioneerEditor = 'ROLE_AUCTIONEER_EDITOR',
  auctioneerContact = 'ROLE_AUCTIONEER_CONTACT',
}

export enum UserPermission {
  canView = 'canView',
  canCreate = 'canCreate',
  canEdit = 'canEdit',
  canDelete = 'canDelete',
}

export enum PermissionGroup {
  auctioneers = 'admin-auctioneers',
  auctioneerInformation = 'admin-auctioneer-information',
  auctioneerAddress = 'admin-auctioneer-address',
  auctioneerEmployees = 'admin-auctioneer-employees',
  auctioneerTypeServices = 'admin-auctioneer-type-services',
  auctioneerPriceList = 'admin-auctioneer-price-list',
  auctioneerDocuments = 'admin-auctioneer-documents',
  myCompany = 'admin-my-company',
  myCompanyAddress = 'admin-my-company-address',
  myCompanyEmployees = 'admin-my-company-employees',
  myCompanyInformation = 'admin-my-company-information',
  buyers = 'admin-buyers',
  administrator = 'admin-administrator',
  priceList = 'admin-price-list',
  auctionDocuments = 'admin-auction-documents',
  auctionInformation = 'admin-auction-information',
  adminBuyerSendMail = 'admin-buyer-send-mail',
}

export enum UserAuctionAuthState {
  unauthorized = 'unauthorized',
  personally = 'personally',
  post = 'post',
  formWithCertificate = 'formWithCertificate',
  dataBoxWithCertificate = 'dataBoxWithCertificate',
  dataBoxWithConvertDocument = 'dataBoxWithConvertDocument',
  userAccountConvertDocument = 'userAccountConvertDocument',
  rejected = 'rejected',
}

export enum UserAuctionSecurityState {
  none = 'none',
  bankAccount = 'bankAccount',
  bankAccountWithVariableSymbol = 'bankAccountWithVariableSymbol',
  bankAccountWithVariableSymbolAndSpecificSymbol = 'bankAccountWithVariableSymbolAndSpecificSymbol',
  bankAccountWithVariableSymbolAndSpecificGeneratedSymbol = 'bankAccountWithVariableSymbolAndSpecificGeneratedSymbol',
  cash = 'cash',
  rejected = 'rejected',
}

export enum DocumentState {
  uploaded = 'uploaded',
  approved = 'approved',
  rejected = 'rejected',
}

export enum AccountAuctionsSections {
  prepared = 'prepared',
  ongoing = 'ongoing',
  ended = 'ended',
  overcharge = 'overcharge',
}

export enum UserAddressTypes {
  invoice = 'invoice',
  delivery = 'delivery',
  residence = 'residence',
}

export enum AuctionStatus {
  prepared = 'prepared',
  ongoing = 'ongoing',
  ended = 'ended',
  overcharge = 'overcharge',
}

export type BaseType = any;

export interface BaseObjectType<T = BaseType> {
  [key: string]: T;
}

export interface BaseSelectType<Value = any> {
  label: string;
  value: Value;
}

export type EnumType = {
  type: string;
  translated: string;
  isDisabled?: boolean;
};

export interface LatLng {
  lat: number;
  lng: number;
}

export interface PaginationParams {
  page?: number;
  perPage?: number;
}

export interface PaginationSortingParams extends PaginationParams {
  sort?: {
    property?: string;
    direction?: string;
  };
}

export interface ErrorsType {
  [key: string]: Array<string>;
}

export interface CountryType {
  id: number | string;
  name: string;
}

export interface RegionType {
  id: number | string;
  name: string;
  country: CountryType;
}

export interface DistrictType {
  id: number;
  name: string;
  region: RegionType;
}

export interface DistrictPartType {
  id: number;
  name: string;
}

export interface BaseResponse<Data extends any = undefined> {
  data: Data;
  message: string;
  errors: ErrorsType;
  code?: number;
  total?: number;
}

export interface SystemInfo {
  post_max_size?: number;
}

export interface AdminPageRequest {
  name: string;
  url: string;
  routeName: string;
  parentPageId: string | number | null;
  menuPosition: number;
  footerPosition: number;
  enabled: boolean;
  footer: boolean;
  menu: boolean;
  perex: string;
  roles?: string[];
}

export interface AdminPageTextBlockRequest {
  pageOrder: number;
  type: string;
  name: string;
  content: string;
}

export interface PageTextBlockType {
  id: string | number;
  pageOrder: number;
  type: string;
  name: string;
  content: string;
}

export interface PageType {
  id: number;
  parentPageId: number;
  name: string;
  content: string;
  menu: boolean;
  menuPosition: number | null;
  footer: boolean;
  footerPosition: number | null;
  url: string;
  routeName: string;
  roles: string[];
  enabled: boolean;
  perex: string;
  pageTextBlocks: PageTextBlockType[];
  systemPage?: boolean;
  showInAdministration: boolean;
  fullUrl: string;
}

export interface PagePropType {
  [key: string]: string | number;
}

export interface LoginResponse {
  token: string;
  refresh_token: string;
}

export interface ConfirmResponse {
  role: string;
  token: string;
  refresh_token: string;
}

export interface AllowedRole {
  type: string;
  translated: string;
}

export interface AllowedUserState {
  state: string;
  name: string;
}

export interface UserPermissions {
  items?: Array<string>;
  groups: {
    [permissionGroupName: string]: Array<string>;
  };
}

export type LastUploadedDocument = {
  createdUser: HistoryAuthorType;
  timeCreated: string;
  timeUpdated: string;
};

export interface UserType {
  type: string;
  translated: string;
  roles: string[];
}

export interface User {
  id: number;
  degreePre: string;
  degreePost: string;
  subjectName: string;
  firstName: string;
  lastName: string;
  email: string;
  buyerTypeTranslation?: string;
  phone: string;
  company?: string;
  buyerType?: string;
  state: 'active' | 'disable' | 'deleted' | 'unverified';
  canDelete?: boolean;
  stateTranslation: string;
  role: string;
  admin?: boolean;
  permissions?: UserPermissions;
  userDocuments?: Array<BuyerDocument>;
  nonDeletedUserDocuments?: Array<BuyerDocument>;
  verificationState: string;
  timeCreated: string;
  createdUser?: HistoryAuthorType;
  lastUploadedDocument: LastUploadedDocument;
  userContactTypes: UserContactTypes[];
  buyerDocumentNotification: boolean;
  contact: boolean;
  type: string;
  accountNumber: string;
}

export interface UserContactTypes {
  id: number;
  type: string;
  typeTranslations: string;
}

export interface UserAuctionChangeAuthorizationData {
  authorizationState: string;
  authorizationStateReason?: string;
}

export interface UserAuctionSecurityData {
  auctionSecurity: string;
  securityStateReason?: string;
}

export interface UserRoleInput {
  label: string;
  name: string;
  checked: boolean;
}

export interface RegistrationValues {
  type: string | undefined;

  companyName: string | undefined;
  ico: string | undefined;
  dic: string | undefined;
  issuer: string | undefined;
  position: string | undefined;

  degreePre: string | undefined;
  degreePost: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: Date | undefined;
  rc: string | undefined;
  personalIdentificationNumber: string | undefined;
  eBox: string | undefined;

  phone: string | undefined;
  bankAccountNumber: string | undefined;
  bankAccountCode: string | undefined;

  address: string | undefined;
  zip: string | undefined;
  city: string | undefined;
  country: string | undefined;
  hasDifferentDeliveryAddress: boolean;
  deliveryAddress: string | undefined;
  deliveryZip: string | undefined;
  deliveryCity: string | undefined;
  deliveryCountry: string | undefined;

  note: string | undefined;
  share: string | undefined;

  doesAggreeTnc: boolean;
  doesAggreePrivacyPolicy: boolean;
  doesAggreeMarketing: boolean;
}

export interface RegistrationConfirmData {
  newPassword: string;
  newPassword1: string;
}

export interface Country {
  id: string;
  name: string;
}

export interface Region {
  id: string;
  name: string;
  country?: Array<any>;
}

export interface District {
  id: string;
  name: string;
  region?: Region;
}

export interface DistrictPart {
  id: string;
  name: string;
  district?: District;
}

export interface TrackVariant {
  name: string;
  value: boolean;
}

export interface File {
  id: number;
  type: string;
  hash: string;
  originalPath: string;
  relativePath: string;
  module: string;
  mimeType: string;
  enabled: boolean;
  originalName: string;
}

export interface ServiceForAuctioneer {
  id: number;
  name: string;
  translatedName: string;
  price: number;
  state: string;
  stateTranslation: string;
  serviceType: string;
  serviceTypeTranslation: string;
  serviceForAuctioneerElectronicAuction: ServiceForAuctioneerElectronicAuction;
  vat: string;
}

export type ServiceForAuctioneerElectronicAuction = {
  percent30000: string;
  percent300000: string;
  percent1000000: string;
  percent3000000: string;
  percent10000000: string;
  percent999999999: string;
  plus30000: string;
  plus300000: string;
  plus1000000: string;
  plus3000000: string;
  plus10000000: string;
  plus999999999: string;
};

export interface TextBlockType {
  id: number;
  name: string;
  machineName: string;
  timeUpdate: Date;
  textBlockContents: [object];
}

export interface TextBlockContent {
  content: string;
  language: string;
  textBlock: number;
}

export interface TextBlockContentsResponse {
  id: number;
  content: string;
  language: string;
  textBlock: number;
  timeUpdate: Date;
}

export interface AuctionInformationsCType {
  id: number;
  title: string;
  text: string;
  type: string;
}

export interface AuctionType {
  id: number;
  type: string;
  translated: string;
  state: string;
}

export interface AuctioneerFilters extends PaginationParams {
  ic?: string;
  email?: string;
  auctioneerName?: string;
  type?: string;
  state?: string;
  testDateFrom?: string;
  testDateTo?: string;
  testDate?: boolean;
  invoice?: boolean;
  noInvoice?: boolean;
  regions?: string;
  search?: string;
}

export interface AuctionUsersFilters extends PaginationSortingParams {
  auctionId?: string | number;
  uniqueCode?: string;
  specificSymbol?: string;
  variableSymbol?: string;
  preEmption?: boolean;
  admitted?: boolean;
  auctionSecurity?: (string | null)[];
  authorizationState?: string;
  fullName?: string;
  withoutWinner?: boolean;
  number?: string;
  status?: (string | null)[];
}

export interface WebAuctioneerFilters extends PaginationParams {
  email?: string;
  auctioneerName?: string;
  phone?: string;
  regions?: string;
}

export interface LogsFilters extends PaginationParams {
  userId?: string;
  name?: string;
  role?: string;
  public?: boolean;
  roles?: string[];
  auction?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface StatisticFilters extends PaginationParams {
  auctioneer?: string;
  year?: string;
  graph?: string;
}

export interface UserFilters extends PaginationParams {
  type?: string;
  name?: string;
  ic?: string;
  phone?: string;
  email?: string;
  role?: string;
  state?: string;
  verificationState?: string;
}

export interface DocumentsFilters extends PaginationParams {
  state?: string; //DocumentState;
  email?: string;
  name?: string;
  userId?: string;
  buyerType?: string;
}

export interface CSASAccount {
  id: string;
  account_number: string;
  account_name: string;
  account_type: string;
}

export interface AuctioneerAccessCSAS {
  id: number;
  timeCreated: string;
  timeUpdated: string;
  createdUserId: string;
  accountId: string | null;
  createdUserFullName: string;
  accessTokenExpirationDt: string;
  refreshTokenExpirationDt: string;
}

export interface AuctioneerAccessFio {
  id: number;
  timeCreated: string;
  timeUpdated: string;
  createdUserId: string;
  createdUserFullName: string;
}

export interface AuctioneerStates {
  type: string;
  translated: string;
}

export interface Auctioneer {
  id: number;
  auctioneerName: string;
  ic: string;
  dic: string;
  email: string;
  phone: string;
  dataBox: string;
  website: string;
  bankAccountNumber: string;
  bankAccountCode: string;
  testDate: boolean;
  testDateFrom: Date;
  testDateTo: Date;
  invoice: boolean;
  invoiceDateFrom: Date;
  invoiceDateTo: Date;
  auctionTypes: Array<string>;
  services: Array<AuctioneerService>;
  timeCreated: Date;
  timeUpdated: Date;
  comment: string;
  executive: string;
  type: 'auctioneer' | 'executor';
  typeTranslation: string;
  state: string;
  stateTranslation: string;
  invoiceAddress: AuctioneerAddress | null;
  deliveryAddress: AuctioneerAddress | null;
  auctioneerNumber: number;
  secondPartFileMark: string;
  referenceNumber: string;
  auctioneerAccessCsas?: AuctioneerAccessCSAS;
  auctioneerAccessFio?: AuctioneerAccessFio;
  automaticPairingPayment?: boolean;
}

export interface AuctioneerDetail extends Auctioneer {
  auctioneer?: Auctioneer;
}

export interface AuctioneerService {
  service: any; // ServiceForAuctoneer;
  price: number;
  active: boolean;
}

export interface AuctioneerAddress {
  id: string | number;
  name?: string;
  country?: CountryType;
  region?: RegionType;
  district?: DistrictType;
  districtPart?: DistrictType;
  cadastralArea: string;
  street: string;
  city: string;
  gps: string;
  houseNumber: string;
  ruian: string;
  latitude: string;
  longitude: string;
  zipCode: string;
  mainAddress: boolean;
  type: string;
  typeTranslated: string;
}

export interface AuctioneerAddressRequest {
  country: string;
  cadastralArea: string;
  region: string;
  district: string;
  street: string;
  city: string;
  houseNumber: string;
  ruian: string;
  latitude: string;
  longitude: string;
  zipCode: string;
  mainAddress: boolean;
  type: string;
  name: string;
}

export interface AuctioneerUser extends User {
  mainEmail: boolean;
  reportEmailWeekly: boolean;
  reportEmailPortalDrazeb: boolean;
  reportEmailSubscribe: boolean;
  reportEmailSubscribeWithoutConvertDocument: boolean;
  reportEmailInvoice: boolean;
  reportEmailHelpRequest: boolean;
  reportEmailApprovedDocument?: boolean;
  reportEmailOutage?: boolean;
  mobile: string;
  landline: string;
  userFunction: string;
  adminDescription: string;
}

export interface HashPDResponse {
  hash: string;
  name: string;
  email: string;
}

export interface AuctioneerUserResponse {
  auctioneer: {
    id: number;
    auctioneerName: string;
    ic: string;
  };
  user: AuctioneerUser;
}

export interface AuctioneerUserContactResponse {
  id: number;
  buyerType?: string;
  degreePre: string;
  degreePost: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  landline: string;
  mainEmail: string;
  mobile: string;
  reportEmailHelpRequest: boolean;
  reportEmailInvoice: boolean;
  reportEmailPortalDrazeb: boolean;
  reportEmailSubscribe: boolean;
  reportEmailSubscribeWithoutConvertDocument: boolean;
  reportEmailWeekly: boolean;
  role: string;
  state: 'active' | 'disable' | 'deleted' | 'unverified';
  stateTranslation: string;
  userFunction: string;
  verificationState: string;
}

export type AuctioneersWebResponse = {
  id: number;
  auctioneerName: string;
  ic: string;
  dic: string;
  email: string;
  phone: string;
  dataBox: string;
  website: string;
  executive: string;
  invoiceAddress: AuctioneerAddress | null;
  deliveryAddress: AuctioneerAddress | null;
};

export interface Service {
  id: number;
  name: string;
  translatedName: string;
}

export interface AuctionService {
  auctioneerServiceElectronicAuction: AuctioneerServiceElectronicAuction;
  id: number;
  price: number;
  serviceForAuctioneer: ServiceForAuctioneer;
  state: string;
  vat: string;
}

export interface AuctioneerServiceElectronicAuction {
  auctioneerService: number;
  percent30000: string;
  percent300000: string;
  percent1000000: string;
  percent3000000: string;
  percent10000000: string;
  percent999999999: string;
  plus30000: string;
  plus300000: string;
  plus1000000: string;
  plus3000000: string;
  plus10000000: string;
  plus999999999: string;
}

export interface AuctionServiceUpdate {
  price: number | string;
  state: string;
  auctioneerServiceElectronicAuction: AuctioneerServiceElectronicAuction;
}

export interface AuctioneerType {
  type: string;
  translated: string;
}

export interface ServicesType {
  type: string;
  translated: string;
  unit: {
    type: string;
    translated: string;
  };
}

export interface WysiwygFileResponse {
  url: string;
}

export interface DocumentMedia {
  id: number;
  type: string;
  hash: string;
  module: string;
  mimeType: string;
  enabled: boolean;
  originalName: string;
  title: string;
  originalPath: string;
  relativePath: string;
  thumbnailUrl?: string;
  embedHash?: string;
}

export interface Document {
  id?: string | number;
  media: DocumentMedia;
  timeCreated: Date;
}

export interface UserDocumentType {
  type: string;
  translated: string;
}

export interface UserDocument extends Document {
  state?: DocumentState;
  translatedState?: string;
  reason?: string;
  type?: string;
  translatedType?: string;
  validity?: Date;
  approvedDt?: Date;
  user?: User;
}

export interface Licitator {
  name: string;
  address: string;
}

export interface AllowedEmails {
  allowedEmails: string;
  allowedInSystem: boolean;
}

export interface UserHistoryChange {
  field: string;
  oldValue: string;
  newValue: string;
  translatedField: string;
  translatedOldValue: string;
  translatedNewValue: string;
}

export interface HistoryUserType {
  email: string;
  firstName: string;
  lastName: string;
  degreePre?: string;
  degreePost?: string;
}

export interface HistoryType {
  id: number;
  timeCreated: string;
  userCreated?: HistoryUserType;
  changes?: UserHistoryChange[];
  historyChanges?: UserHistoryChange[];
  changesTranslation?: UserHistoryChange[];
}

export interface HistoryAuthorType {
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  degreePre?: string;
  degreePost?: string;
}

export type AuthenticationFormFormat = 'pdf' | 'doc';

export interface AuthenticationFormType {
  id: number;
  type: string;
  hash: string;
  originalPath: string;
  relativePath: string;
  module: string;
  mimeType: string;
  enabled: boolean;
  originalName: string;
  title: string;
}

export interface UserEmailType {
  id: number;
  generatedId: string;
  subject: string;
  date: string;
  timeCreated?: string;
  attachments: [UserEmailAttachment];
}

export interface UserEmailAttachment {
  loggedEmailId: number;
  fileName: string;
  id: string;
  type: string;
}

export interface BuyerDocument {
  id: string | number;
  media: DocumentMedia;
  state: string;
  reason?: string;
  type: string;
  translatedState: string;
  translatedType: string;
  approvedUser?: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface DocumentCreate {
  mime: string;
  type: string;
  data: string;
  original_name: string;
  document_type?: string;
}

export interface AdminProgressFormMenuItemType {
  label: string;
  url?: string;
  routeName?: string;
  isActive?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  outsideUrl?: string;
}

export interface FrontSidebarMenuItemType {
  label: string;
  count?: number;
  link?: string;
  testId?: string;
  active?: boolean;
  onClick?: () => void;
}

export interface FrontMobileMenuItemType {
  label: string;
  count?: number;
  active?: boolean;
}

export interface FrontTabsMenuItemType {
  label: string;
  count?: number;
  hide?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}

export interface FrontBannerNavigationItemType {
  text: ReactNode;
  icon: string;
  iconActive: string;
  active?: boolean;
  btnText: string;
  btnClassName?: string;
  style?: CSSProperties;
  onClick: () => void;
}

export interface HeaderUserMenuItemType {
  label: string;
  link: string;
}

export interface Translation {
  id: number;
  domain: string;
  t_key: string;
  cs: string;
  en: string;
}

export interface AuctionAdminBid {
  id: string | number;
  userAuction: {
    uniqueCode: number;
    user: {
      id: string | number;
      firstName: string;
      lastName: string;
      degreePre?: string;
      degreePost?: string;
      company?: string;
    };
  };
  bid: number;
  bidPrice: number;
  timeCreated: string;
}

export interface AuctionFilters extends PaginationParams {
  ic?: string;
  email?: string;
  auctioneerName?: string;
  auctionType?: string;
  state?: string;
  testDateFrom?: string;
  testDateTo?: string;
  testDate?: boolean;
  invoice?: boolean;
  noInvoice?: boolean;
  status?: string;
  auctioneer?: string;
  mainCategory?: string;
  subCategory?: string;
  published?: boolean;
  id?: string;
  title?: string;
  number?: string;
  startDtFrom?: string;
  startDtTo?: string;
  endDtFrom?: string;
  endDtTo?: string;
  active?: boolean;
}

export interface AdminAuctionFilters extends PaginationSortingParams {
  ic?: string;
  email?: string;
  auctioneerName?: string;
  auctionType?: string;
  state?: string;
  testDateFrom?: string;
  testDateTo?: string;
  testDate?: boolean;
  invoice?: boolean;
  noInvoice?: boolean;
  status?: (string | null)[];
  auctioneer?: string;
  mainCategory?: string;
  subCategory?: string;
  published?: boolean;
  id?: string;
  title?: string;
  number?: string;
  startDtFrom?: string;
  startDtTo?: string;
  endDtFrom?: string;
  endDtTo?: string;
  active?: boolean;
}

export interface AuctionStatusResponse {
  type: string;
  translated: string;
}

export interface AuctionAuctionTypeResponse {
  type: string;
  translated: string;
}

export interface AuctionStatusGroup {
  type: string;
  translated: string;
}

export interface AuctionDocumentTypeResponse {
  [key: string]: string;
}

export interface AuctionTypeResponse {
  type: string;
  translatedName: string;
}

export interface AuctionExportsResponse {
  server: string;
  serverTranslation: string;
  state: 'active' | 'deactive';
  statusTranslation: string;
  dateFrom: Date;
  dateTo: Date;
  timeUpdated: string;
  daysToPay: number;
  showHistory?: boolean;
  exportStatus?: string;
  lastExportStatus?: string;
}

export interface AuctionExportsHistoryResponse {
  action: 'export' | 'cancellation';
  actionTranslate: string;
  auctionExport: AuctionExportsResponse;
  id: string | number;
  timeCreated: Date;
  timeFrom: Date;
  timeTo: Date;
  user: User;
  status: string;
  statusTranslation: string;
  messageTranslation: string;
  exportToday: boolean;
}

export interface AuctionItemTypeResponse {
  [key: string]: string;
}

export interface AuctionCategoryReponse {
  id: number;
  title: string;
  auctionParameterType: string;
  machineName: string;
  parentId?: number;
  parentTitle?: string;
  mandatoryHouseNumber?: boolean;
  parentMachineName?: string;
}

export interface AuctionDocumentCreate {
  mime: string;
  type: string;
  data: string;
  original_name: string;
}

export interface AuctionImageCreate {
  mime: string;
  type: string;
  data: string;
  image_title: string;
  original_name: string;
}

export interface AuctionVideoCreate {
  mime: string;
  data: string;
}

export interface AuctionInformationDocumentCreate {
  mime: string;
  type: string;
  data: string;
  original_name: string;
  title: string;
}

export interface AuctionRequest {
  number: string;
  numberPrefix: string;
  numberSuffix: string;
  startDt: string;
  endDt: string;
  afterNovel: boolean;
  status: string;
  type: string;
  auctioneer: string | number;
  auctionCautionsType: AuctionCautionsType[];
  auctionCategory: string | number;
  title: string;
  hash: string;
  urlOffice: string;
  minimalBid: string;
  estimatedPrice: string;
  minPurchasePrice: string;
  cautionDeposit: string;
  visitationDescription: string;
  placeAuctionDescription: string;
  description: string;
  internalDescription: string;
  auctionParameterRealEstateHouse?: AuctionParameterRealEstateHouse;
  auctionParameterRealEstateFlat?: AuctionParameterRealEstateFlat;
  auctionParameterRealEstateLand?: AuctionParameterRealEstateLand;
  auctionParameterVehicleCar?: AuctionParameterVehicleCar;
  auctionParameterVehicleMotorcycle?: AuctionParameterVehicleMotorcycle;
  auctionParameterVehicleOther?: AuctionParameterVehicleOther;
  auctionConceptId?: string | number;
}

export interface AuctionImageMediaType {
  enabled: boolean;
  hash: string;
  id: number;
  mimeType: string;
  module: string;
  originalName: string;
  originalPath: string;
  relativePath: string;
  type: string;
  thumbnailUrl?: string;
  embedHash?: string;
}

export interface AuctionImageType {
  auction: number;
  id: number;
  media: AuctionImageMediaType;
  position: number;
  title: string;
}

export interface AuctionTypeResponse {
  id: number;
  state: string;
  translatedType: string;
  type: string;
}

export interface AuctionCountResponse {
  title: string;
  count: number;
  status: string;
  link?: string;
}

export interface AuctionConceptType {
  id: string | number;
  timeCreated: string;
  data: any;
  auctionType: string;
}

export interface AuctionAuthorizationType {
  type: string;
  translated: string;
  typeTranslation?: string;
}

export interface AuctionSecurityCounterInfo {
  counterMessage: string;
}

export interface AuctionCautionDepositType {
  type: string;
  translated: string;
}

export interface AuctionCautionCash {
  enabled: boolean;
}

export interface AuctionCautionBankGuarantee {
  enabled: boolean;
}

export interface AuctionCautionBankAccount {
  enabled: boolean;
  buyerIdentifier: string;
  bankAccountNumber: string;
  bankAccountCode: string;
  variableSymbol: string;
  variableSymbolType: string;
  specificSymbol: string;
  specificSymbolType: string;
}

export interface AuctionResponse {
  id: number;
  createdUserId?: string;
  createdUserFullName?: string;
  timeCreated?: string;
  number: string;
  auctionExportSummary: string;
  auctionExportSummaryState: boolean | null;
  numberPrefix: string;
  numberSuffix: string;
  startDt: string;
  endDt: string;
  outage: boolean;
  realEndDt?: string;
  canBeExportedToPd?: boolean;
  displayAuctionDt: boolean;
  afterNovel: boolean;
  canProtest: boolean;
  protestEndDatetime?: string;
  isFavorite?: boolean;
  isJoined?: boolean;
  canJoin?: boolean;
  protestTime?: string;
  isEnded: boolean;
  joinAuctionButtonText: string;
  state: string;
  active: boolean;
  status: string;
  statusTranslation?: string;
  parentStatus?: string;
  parentStatusTranslation?: string;
  type?: string;
  auctionType?: string;
  typeTranslation?: string;
  itemType: string;
  title: string;
  rawTitle: string;
  generateTitle: boolean;
  webUrl: string;
  part: string;
  hash: string;
  urlOffice: string;
  minimalBid: string;
  minimalThrow: string;
  minPurchasePrice: string;
  estimatedPrice: string;
  cautionDeposit: string;
  visitationDescription: string;
  placeAuctionDescription: string;
  description: string;
  printDescription: string;
  descriptionForRealEstatePortal?: string;
  internalDescription: string;
  numberOfJoinedUsers: number;
  numberOfAdmittedUsers: number;
  numberOfAuctionSecurityPaid: number;
  exportedToWeb: boolean;
  exportedToPD: boolean;
  exportedToRealEstateCount: number;
  exportedToRealEstate: string;
  auctionCautionCash: AuctionCautionCash;
  auctionCautionBankGuarantee: AuctionCautionBankGuarantee;
  auctionCautionBankAccount: AuctionCautionBankAccount;
  auctioneer: Auctioneer;
  auctionCategory: AuctionCategoryReponse;
  auctionAddress: AuctionAddress;
  auctionMedia: AuctionImageType[];
  auctionDocuments: AuctionDocumentResponse[];
  auctionPublicDocuments: AuctionDocumentResponse[];
  auctionAuthorizationsType: AuctionAuthorizationType[];
  auctionParameterRealEstateHouse?: AuctionParameterRealEstateHouse;
  auctionParameterRealEstateFlat?: AuctionParameterRealEstateFlat;
  auctionParameterRealEstateLand?: AuctionParameterRealEstateLand;
  auctionParameterRealEstateCommercial?: AuctionParameterRealEstateCommercial;
  auctionParameterRealEstateOther?: AuctionParameterRealEstateOther;
  auctionParameterVehicleCar?: AuctionParameterVehicleCar;
  auctionParameterVehicleMotorcycle?: AuctionParameterVehicleMotorcycle;
  auctionParameterVehicleOther?: AuctionParameterVehicleOther;
  translations?: BaseObjectType;
  contactPerson: ContactPerson;
  repeated: boolean;
  overcharge: boolean;
  draw: boolean;
  canExtendOnGoing?: boolean;
  drawToDt: string;
  winnerBid?: {
    bidPrice: number;
  };
}

export interface AuctionMapResponse {
  auction: number;
  auctionCategoryParentTitle: string;
  auctionCategoryTitle: string;
  auctionType: string;
  auctionTypeTranslation: string;
  cautionDeposit: string;
  city: string;
  latitude: number;
  longitude: number;
  mediaHash: string;
  number: string;
  region: string;
  title: string;
  translations: BaseObjectType;
}

export type ContactPerson = {
  createdUser: HistoryAuthorType;
  email: string;
  firstName: string;
  lastName: string;
  degreePre?: string;
  degreePost?: string;
  id: number;
  timeCreated: string;
  timeUpdated: string;
  phone: string;
};

export type AuctionCautionsOptions = 'none' | 'cash' | 'bankAccount' | 'rejected';

export interface AuctionCautionsType {
  type: AuctionCautionsOptions;
  bankAccountNumber?: string;
  bankAccountCode?: string;
  variableSymbol?: string;
  variableSymbolType?: 'rc_ico' | 'own';
  specificSymbol?: string;
  specificSymbolType?: 'rc_ico' | 'own' | 'generated' | 'none';
}

export interface AuctionAddress {
  latitude: number;
  longitude: number;
  cadastralArea: string;
  country: string;
  region: AuctionAddressRegion;
  district: AuctionAddressDistrict;
  city: string;
  street: string;
  landNumber: string;
  houseNumber: string;
  ruian: string;
  gps: string;
  zipCode: string;
}

export interface AddressType {
  addressCadastral: string;
  addressGps: string;
  addressStreet: string;
  addressHouseNumber: string;
  addressCity: string;
  addressZipCode: string;
  addressCountry: string;
  addressRegion: string;
  addressDistrict: string;
  addressDistrictPart?: string;
  addressRuian: string;
}

export interface AddressSuggestType {
  lat: number;
  long: number;
  suggestFirstRow: string;
  suggestSecondRow: string;
  suggestThirdRow: string;
}

export interface AuctionAddressDistrict {
  id: number;
  name: string;
}

export interface AuctionAddressRegion {
  id: number;
  name: string;
}

export interface AuctionDocumentResponse {
  id: string;
  documentType: string;
  documentTypeTranslation: string;
  public: boolean;
  media: DocumentMedia;
}

export interface AuctionImageResponse {
  id: string;
  title: string;
  media: DocumentMedia;
}

export interface UserAuctionResponse {
  auction: AuctionResponse;
  user: User;
  backSecurity: boolean;
  auctionInformedConsents: AuctionInformedConsent[];
  specificSymbol: string;
  variableSymbol: string;
  admitted: boolean;
  preEmption: boolean;
  ownershipPreEmption: boolean;
  ownershipPreEmptionWeight?: number;
  joinedTime: Date;
  uniqueCode: number;
  auctionSecurity: string;
  auctionSecurityTranslation: string;
  authorizationState: string;
  authorizationStateTranslation: string;
  securityPaid: boolean;
  canLogout: boolean;
  canDraw: boolean;
  isWinner?: boolean;
  createdDt?: string;
  countAuctionBids?: number;
  hasUserInformedConsents?: boolean;
  hasActiveProtest: boolean;
}

export interface AuctionInformationDocumentResponse {
  id: number;
  media: {
    id: number;
    type: string;
    hash: string;
    module: string;
    mimeType: string;
    enabled: boolean;
    originalName: string;
    title: string;
  };
}

export interface AuctionInformationResponse {
  id: number;
  title: string;
  content: string;
  position: number;
  showTimeType: string;
  showTimeTypeTranslation: string;
  category: string;
  date: string;
  state: string;
  categoryKey: string;
  stateTranslation: string;
  auctionInformationDocuments: AuctionInformationDocumentResponse[];
}

export interface AuctionInformationRequest {
  title: string;
  content: string;
  position?: string;
  showTimeType?: string;
  category: string;
  categoryKey?: string;
  date?: string;
}

export interface AuctionFieldsEnabled {
  id: boolean;
  exportedToRealEstateCount: boolean;
  numberOfJoinedUsers: boolean;
  numberOfAdmittedUsers: boolean;
  statusTranslation: boolean;
  stateTranslation: boolean;
  numberOfAuctionSecurityPaid: boolean;
  exportedToPD: boolean;
  exportedToWeb: boolean;
  exportedToRealEstate: boolean;
  auctioneer: boolean;
  contactPerson: boolean;
  numberPrefix: boolean;
  numberSuffix: boolean;
  number: boolean;
  startDt: boolean;
  endDt: boolean;
  realEndDt: boolean;
  status: boolean;
  draw: boolean;
  drawToDt: boolean;
  auctionType: boolean;
  repeated: boolean;
  overcharge: boolean;
  overchargeDt: boolean;
  auctionCategory: boolean;
  auctionAddress: boolean;
  title: boolean;
  hash: boolean;
  urlOffice: boolean;
  minimalThrow: boolean;
  minimalBid: boolean;
  estimatedPrice: boolean;
  minPurchasePrice: boolean;
  cautionDeposit: boolean;
  visitationDt: boolean;
  visitationMeetingPlace: boolean;
  visitationDescription: boolean;
  placeAuctionDescription: boolean;
  description: boolean;
  webUrl: boolean;
  part: boolean;
  internalDescription: boolean;
  afterNovel: boolean;
  state: boolean;
  portalDrazebId: boolean;
  sRealityId: boolean;
  realityIdnesId: boolean;
  tipCarsId: boolean;
  protestTimeout: boolean;
  auctionParameterRealEstateHouse: boolean;
  auctionParameterRealEstateFlat: boolean;
  auctionParameterRealEstateLand: boolean;
  auctionParameterVehicleCar: boolean;
  auctionParameterVehicleMotorcycle: boolean;
  auctionParameterVehicleOther: boolean;
  auctionMedia: boolean;
  auctionDocuments: boolean;
  auctionCautionCash: boolean;
  auctionCautionBankAccount: boolean;
  auctionAuthorizationsType: boolean;
  auctionInvoice: boolean;
  typeTranslation: boolean;
  timeCreated: boolean;
  timeUpdated: boolean;
  createdUser: boolean;
}

export interface LogResponse {
  id: number;
  user?: User;
  entity: string;
  entity_key: string;
  action: string;
  detail: string;
  timeCreated: string;
  ipAddress: string;
}

export interface UserAdditionalPersonValues {
  firstName: string;
  lastName: string;
  birthdate: string;
  personalIdentificationNumber: string;
  identityCardNumber: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  share?: string;
}

export interface BuyerFormValues {
  registrationType?: string;
  email?: string;
  type: string | undefined;
  companyName: string | undefined;
  ico: string | undefined;
  dic: string | undefined;
  issuer: string | undefined;
  position: string | undefined;
  degreePre: string | undefined;
  degreePost: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: Date | undefined;
  identityCardNumber: string | undefined;
  personalIdentificationNumber: string | undefined;
  eBox: string | undefined;
  phone: string | undefined;
  citizenship: string;
  bankAccountNumber: string | undefined;
  bankAccountCode: string | undefined;
  address: string | undefined;
  zip: string | undefined;
  city: string | undefined;
  country: string | undefined;
  addressId?: string;
  addressIndex?: number;
  deliveryAddressId?: string;
  deliveryAddressIndex?: number;
  invoiceAddressId?: string;
  invoiceAddressIndex?: number;
  hasDifferentDeliveryAddress: boolean | undefined;
  hasDifferentInvoiceAddress: boolean | undefined;
  deliveryAddress: string | undefined;
  deliveryZip: string | undefined;
  deliveryCity: string | undefined;
  deliveryCountry: string | undefined;
  invoiceAddress: string | undefined;
  invoiceZip: string | undefined;
  invoiceCity: string | undefined;
  invoiceCountry: string | undefined;
  note: string | undefined;
  share: string | undefined;
  doesAggreeTnc?: boolean;
  doesAggreePrivacyPolicy?: boolean;
  doesAggreeMarketing?: boolean;
  newsletter?: boolean;
  portalConsent?: boolean;
  gdprConsent?: boolean;
  captcha?: string;
  userAdditionalsPerson?: UserAdditionalPersonValues[];
}

export interface BuyerAddress {
  id?: string;
  type: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  mainAddress: boolean;
}

export interface BuyerIndividual {
  id?: string | number;
  buyerType: string;
  degreePre: string;
  degreePost: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dataBox: string;
  citizenship: string;
  accountNumber: string;
  birthdate: string;
  personalIdentificationNumber: string;
  identityCardNumber: string;
  userAddresses: BuyerAddress[];
  userAdditionalsPerson?: UserAdditionalPersonValues[];
}

export interface BuyerOwnAccountWorker {
  buyerType: string;
  degreePre: string;
  degreePost: string;
  firstName: string;
  lastName: string;
  ico: string;
  dic: string;
  email: string;
  phone: string;
  dataBox: string;
  accountNumber: string;
  birthdate: string;
  citizenship: string;
  personalIdentificationNumber: string;
  identityCardNumber: string;
  userAddresses: BuyerAddress[];
}

export interface BuyerLegal {
  id?: string | number;
  buyerType: string;
  email: string;
  phone: string;
  dataBox: string;
  accountNumber: string;
  company: string;
  ico: string;
  dic: string;
  register: string;
  position: string;
  degreePre: string;
  degreePost: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  citizenship: string;
  identityCardNumber: string;
  personalIdentificationNumber: string;
  userAddresses: BuyerAddress[];
  userAdditionalsPerson?: [];
}

export interface BuyerSJMPV {
  id?: string | number;
  buyerType: string;
  degreePre: string;
  degreePost: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  citizenship: string;
  dataBox: string;
  accountNumber: string;
  birthdate: string;
  personalIdentificationNumber: string;
  identityCardNumber: string;
  share?: string;
  userAddresses: BuyerAddress[];
  userAdditionalsPerson?: UserAdditionalPersonValues[];
}

export interface BuyerCreateResponse {
  id: string;
}

export interface BuyerAuctionResponse {
  auction: AuctionResponse;
  user: BuyerIndividual | BuyerLegal | BuyerSJMPV;
  joinedTime: Date;
  uniqueCode: number;
  auctionSecurity: string;
  authorizationState: string;
  securityStateReason: string;
  authorizationStateReason: string;
  auctionSecurityTranslation: string;
  authorizationStateTranslation: string;
  variableSymbol: string;
  specificSymbol: string;
  preEmption: boolean;
  ownershipPreEmption: boolean;
  ownershipPreEmptionWeight: string;
}

export interface WebAuctionsFilter extends PaginationParams {
  title?: string;
  priceFrom?: string;
  priceTo?: string;
  state?: string;
  status?: string;
  mainCategory?: string;
  regions?: string[];
  districts?: string[];
  districtsParts?: string[];
  subCategories?: string[];
  auctionType?: string;
  auctioneer?: string;
}

export interface WebCategories {
  id: string;
  title: string;
  count: number;
}

export interface AuctionStatusGroupChildren {
  disabled: boolean;
  status: string;
  statusTranslation: string;
}

export interface AuctionStatusGroupResponse {
  statusGroup: string;
  statusGroupTranslation: string;
  children: AuctionStatusGroupChildren[];
}

export interface AdminAuctionImageType {
  src?: string;
  srcDetail?: string;
  type?: string;
  embedHash?: string;
}

export type AuctionParameterType =
  | 'real_estate_house'
  | 'real_estate_flat'
  | 'real_estate_land'
  | 'vehicle_car'
  | 'vehicle_motorcycle'
  | 'vehicle_other'
  | string;

export interface AuctionParametersResponse {
  id: string;
  title: string;
  annuity?: boolean;
  mandatoryRuian?: boolean;
  mandatoryHouseNumber?: boolean;
}

export interface AuctionParametersShortCutResponse {
  id: string;
  title: string;
  shortcut: string;
}

export interface AuctionParametersShortCutGroupResponse {
  id: string;
  title: string;
  shortcut: string;
  parameterVehicleOtherGroup: Array<string>;
}

export interface BaseAuctionParam {
  id: string;
  title: string;
  annuity?: boolean;
}

export interface AuctionRealEstateAllParams {
  parameterRealEstateElectricity: BaseAuctionParam[];
  parameterRealEstateGas: BaseAuctionParam[];
  parameterRealEstatePlacement: BaseAuctionParam[];
  parameterRealEstateWaste: BaseAuctionParam[];
  parameterRealEstateWater: BaseAuctionParam[];
  parameterRealEstateParking: BaseAuctionParam[];
  parameterRealEstateBuildingConstruction: BaseAuctionParam[];
  parameterRealEstateObjectStatus: BaseAuctionParam[];
  parameterRealEstateOwnership: BaseAuctionParam[];
  parameterRealEstateHeating: BaseAuctionParam[];
  parameterRealEstateEnergyEfficiencyRating: BaseAuctionParam[];
  parameterRealEstateFurnished: BaseAuctionParam[];
  parameterRealEstateProtection: BaseAuctionParam[];
  parameterRealEstateRoadType: BaseAuctionParam[];
  parameterRealEstateSurroundingsType: BaseAuctionParam[];
  parameterRealEstateTelecommunication: BaseAuctionParam[];
  parameterRealEstateTransport: BaseAuctionParam[];
}

export interface AuctionRealEstateFlatParams {
  parameterRealEstateType: BaseAuctionParam[];
  parameterRealEstateFlatClass: BaseAuctionParam[];
}

export interface AuctionRealEstateHouseParams {
  parameterRealEstateHouseLocationObject: BaseAuctionParam[];
  parameterRealEstateHouseObjectType: BaseAuctionParam[];
  parameterRealEstateHouseRoomCount: BaseAuctionParam[];
}

export interface AuctionVehicleAllParams {
  parameterVehicleColor: BaseAuctionParam[];
  parameterVehicleFuel: BaseAuctionParam[];
  parameterVehicleOriginCountry: BaseAuctionParam[];
  parameterVehicleCategory: BaseAuctionParam[];
}

export interface AuctionVehicleCarParams {
  parameterVehicleCarBody: BaseAuctionParam[];
  parameterVehicleCarBrand: BaseAuctionParam[];
}

export interface AuctionVehicleMotorcycleParams {
  parameterVehicleMotorcycle: BaseAuctionParam[];
  parameterVehicleMotorcycleBrand: BaseAuctionParam[];
}

export interface AuctionParameterRealEstateHouse {
  id: number;
  parameterRealEstateContractType: {
    id: number;
    title: string;
  };
  parameterRealEstateType: {
    id: number;
    title: string;
  };
  numberOfFloors: number;
  numberOfFloorsAboveGround: number;
  parameterRealEstateHouseLocationObject: {
    id: number;
    title: string;
  };
  parameterRealEstatePlacement: {
    id: number;
    title: string;
  };
  parameterRealEstateFurnished: {
    id: number;
    title: string;
  };
  parameterRealEstateProtection: {
    id: number;
    title: string;
  };
  parameterRealEstateSurroudingsType: {
    id: number;
    title: string;
  };
  personal: boolean;
  parameterRealEstateRoadType: {
    id: number;
    title: string;
  };
  parameterRealEstateHouseObjectType: {
    id: number;
    title: string;
  };

  parameterRealEstateHouseType: {
    id: number;
    title: string;
    mandatoryHouseNumber: boolean;
  };

  easyAccess: boolean;
  balcony: boolean;
  balconyArea: number;
  elevator: boolean;
  loggia: boolean;
  loggiaArea: number;
  mortgage: boolean;
  mortgagePercent: number;
  basin: boolean;
  basinArea: number;
  annuity: number;
  parameterRealEstateEnergyEfficiencyRating: {
    id: number;
    title: string;
  };
  parameterRealEstateOwnership: {
    id: number;
    title: string;
  };
  parameterRealEstateBuildingConstruction: {
    id: number;
    title: string;
  };
  parameterRealEstateObjectStatus: {
    id: number;
    title: string;
  };
  builtUpArea: number;
  floorArea: number;
  landArea: number;
  gardenArea: number;
  cellar: boolean;
  cellarArea: number;
  terrace: boolean;
  terraceArea: number;
  parameterRealEstateHeating: {
    id: number;
    title: string;
  };
  parameterRealEstateWater: {
    id: number;
    title: string;
  };
  parameterRealEstateElectricity: {
    id: number;
    title: string;
  };
  parameterRealEstateWaste: {
    id: number;
    title: string;
  };
  parameterRealEstateGas: {
    id: number;
    title: string;
  };
  parameterRealEstateParking: {
    id: number;
    title: string;
  };
  parameterRealEstateTelecommunication: {
    id: number;
    title: string;
  };
  housingUnits: number;
  objectYear: number;
  reconstructionYear: number;
}

export interface AuctionParameterRealEstateFlat {
  id: number;
  parameterRealEstateContractType: {
    id: number;
    title: string;
  };
  parameterRealEstateType: {
    id: number;
    title: string;
  };
  parameterRealEstateBuildingConstruction: {
    id: number;
    title: string;
  };
  parameterRealEstateObjectStatus: {
    id: number;
    title: string;
  };
  parameterRealEstateOwnership: {
    id: number;
    title: string;
  };
  usableArea: number;
  floorArea: number;
  numberOfFloors: number;
  floor: number;
  parameterRealEstateHeating: {
    id: number;
    title: string;
  };
  parameterRealEstateParking: {
    id: number;
    title: string;
  };
  parameterRealEstatePlacement: {
    id: number;
    title: string;
  };
  parameterRealEstateFurnished: {
    id: number;
    title: string;
  };
  parameterRealEstateFlatClass: {
    id: number;
    title: string;
  };
  parameterRealEstateProtection: {
    id: number;
    title: string;
  };
  parameterRealEstateSurroundingsType: {
    id: number;
    title: string;
  };
  personal: boolean;
  parameterRealEstateRoadType: {
    id: number;
    title: string;
  };
  parameterRealEstateEnergyEfficiencyRating: {
    id: number;
    title: string;
  };
  parameterRealEstateTelecommunication: {
    id: number;
    title: string;
  };
  gardenArea: number;
  objectYear: number;
  reconstructionYear: number;
  easyAccess: boolean;
  balcony: boolean;
  balconyArea: number;
  basin: boolean;
  basinArea: number;
  cellar: boolean;
  cellarArea: number;
  terrace: boolean;
  terraceArea: number;
  loggia: boolean;
  loggiaArea: number;
  mortgage: boolean;
  mortgagePercent: number;
  elevator: boolean;
  annuity: number;
}

export interface AuctionParameterRealEstateLand {
  id: number;
  parameterRealEstateLandGroup: {
    id: number;
    title: string;
  };
  usableArea: number;
  landArea: number;
  parameterRealEstateWater: {
    id: number;
    title: string;
  };
  parameterRealEstateElectricity: {
    id: number;
    title: string;
  };
  parameterRealEstateWaste: {
    id: number;
    title: string;
  };
  parameterRealEstateGas: {
    id: number;
    title: string;
  };
  parameterRealEstatePlacement: {
    id: number;
    title: string;
  };
  coordinateX: number;
  coordinateY: number;
  parameterRealEstateRoadType: {
    id: number;
    title: string;
  };
  parameterRealEstateProtection: {
    id: number;
    title: string;
  };
  personal: boolean;
  mortgage: boolean;
  mortgagePercent: number;
  annuity: number;
}

export interface AuctionParameterRealEstateCommercial {
  id: number;
  parameterRealEstateLandGroup: {
    id: number;
    title: string;
  };
  usableArea: number;
  landArea: number;
  parameterRealEstateWater: {
    id: number;
    title: string;
  };
  parameterRealEstateElectricity: {
    id: number;
    title: string;
  };
  parameterRealEstateWaste: {
    id: number;
    title: string;
  };
  parameterRealEstateGas: {
    id: number;
    title: string;
  };
  parameterRealEstatePlacement: {
    id: number;
    title: string;
  };
  coordinateX: number;
  coordinateY: number;
  parameterRealEstateRoadType: {
    id: number;
    title: string;
  };
  parameterRealEstateProtection: {
    id: number;
    title: string;
  };
  personal: boolean;
  mortgage: boolean;
  mortgagePercent: number;
  annuity: number;
}

export interface AuctionParameterRealEstateOther {
  id: number;
  parameterRealEstateLandGroup: {
    id: number;
    title: string;
  };
  usableArea: number;
  landArea: number;
  parameterRealEstateWater: {
    id: number;
    title: string;
  };
  parameterRealEstateElectricity: {
    id: number;
    title: string;
  };
  parameterRealEstateWaste: {
    id: number;
    title: string;
  };
  parameterRealEstateGas: {
    id: number;
    title: string;
  };
  parameterRealEstatePlacement: {
    id: number;
    title: string;
  };
  coordinateX: number;
  coordinateY: number;
  parameterRealEstateRoadType: {
    id: number;
    title: string;
  };
  parameterRealEstateProtection: {
    id: number;
    title: string;
  };

  parameterRealEstateOtherCategory?: {
    id: number;
    title: string;
    mandatoryRuian: boolean;
    mandatoryHouseNumber: boolean;
  };
  personal: boolean;
  mortgage: boolean;
  mortgagePercent: number;
  annuity: number;
}

export interface AuctionParameterVehicleCar {
  id: number;
  parameterVehicleCategory: {
    id: string;
    title: string;
    shortcut: string;
  };
  parameterVehicleCarBrand: {
    id: number;
    title: string;
    shortcut: string;
  };
  parameterVehicleCarModel: {
    id: number;
    title: string;
    shortcut: string;
  };
  description: string;
  parameterVehicleCarBody: {
    id: number;
    title: string;
  };
  parameterVehicleColor: {
    id: number;
    title: string;
  };
  vin: string;
  vinPublic: boolean;
  parameterVehicleState: {
    id: number;
    title: string;
  };
  tachometer: number;
  dateOfManufacture: string;
  dateOfTechnicalInspection: Date;
  parameterVehicleFuel: {
    id: number;
    title: string;
  };
  enginePower: number;
  engineCapacity: number;
  parameterVehicleOriginCountry: {
    id: number;
    title: string;
  };
  firstOwner: boolean;
  serviceBook: boolean;
  afterCrash: boolean;
  numberOfSeats: number;
}

export interface AuctionParameterVehicleMotorcycle {
  id: number;
  parameterVehicleCategory: {
    id: string;
    title: string;
    shortcut: string;
  };
  parameterVehicleMotorcycleBrand: {
    id: number;
    title: string;
    shortcut: string;
  };
  description: string;
  parameterVehicleMotorcycleBody: {
    id: number;
    title: string;
  };
  parameterVehicleColor: {
    id: number;
    title: string;
  };
  vin: string;
  vinPublic: boolean;
  parameterVehicleState: {
    id: number;
    title: string;
  };
  tachometer: number;
  dateOfManufacture: Date;
  dateOfTechnicalInspection: Date;
  parameterVehicleFuel: {
    id: number;
    title: string;
  };
  enginePower: number;
  engineCapacity: number;
  parameterVehicleOriginCountry: {
    id: number;
    title: string;
  };
  firstOwner: boolean;
  serviceBook: boolean;
  afterCrash: boolean;
}

export interface AuctionParameterVehicleOther {
  id: number;
  parameterVehicleCategory: {
    id: string;
    title: string;
    shortcut: string;
  };
  parameterVehicleOtherGroup: {
    id: number;
    title: string;
  };
  parameterVehicleOtherBrand: {
    id: number;
    title: string;
    shortcut: string;
  };
  parameterVehicleOtherBody: {
    id: number;
    title: string;
    shortcut: string;
  };
  description: string;
  parameterVehicleColor: {
    id: number;
    title: string;
  };
  vin: string;
  vinPublic: boolean;
  parameterVehicleState: {
    id: number;
    title: string;
  };
  tachometer: number;
  dateOfManufacture: Date;
  dateOfTechnicalInspection: Date;
  parameterVehicleFuel: {
    id: number;
    title: string;
  };
  enginePower: number;
  engineCapacity: number;
  parameterVehicleOriginCountry: {
    id: number;
    title: string;
  };
  firstOwner: boolean;
  serviceBook: boolean;
  afterCrash: boolean;
}

export interface HelpRequestValues {
  name: string;
  email: string;
  phone: string;
  message: string;
  captcha: string;
}

export interface WatchDogType {
  id: string;
  title: string;
  state: string;
  priceFrom: number;
  priceTo: number;
  keywords: string;
  stateTranslation: string;
  watchDogInterval: string;
  watchDogIntervalTranslation: string;
  trackAll: boolean;
  auctionWatchDogCategories: {
    auctionCategory: {
      id: string;
      parentId: string;
    };
  }[];
  auctionWatchDogRegions: {
    region: { id: string };
    auctionWatchDogDistricts: {
      id: string;
      district: { id: string };
      auctionWatchDogDistrictParts: { districtPart: { id: string } }[];
    }[];
  }[];
}

export interface WatchDogRequestType {
  title: string;
  state: string;
  priceFrom: string;
  priceTo: string;
  keywords: string;
  watchDogInterval: string;
  trackAll: boolean;
  auctionWatchDogCategories: { auctionCategory: string }[];
  auctionWatchDogRegions: {
    region: string;
    auctionWatchDogDistricts: { district: string }[];
  }[];
}

export interface WebAuctionBidUser {
  uniqueCode: string;
}

export interface WebAuctionBid {
  id: string;
  bid: number;
  bidPrice: number;
  timeCreated: string;
  userAuction: WebAuctionBidUser;
  draw: BarControllerChartOptions;
  drawNumber: boolean;
  drawDt: string;
  extended: boolean;
}

export interface WebAuctionBidRequest {
  bid: number;
  bidPrice: number;
  acceptBigBid?: boolean;
}

export interface WebInformationResponse {
  id: number;
  position?: number;
  category: string;
  categoryKey?: string;
  content: string;
  contentTranslation: string;
  date: string;
  showTimeType: string;
  showTimeTypeTranslation: string;
  state: string;
  stateTranslation: string;
  title: string;
  auctionInformationDocuments: AuctionInformationDocumentResponse[];
}

export interface AuctionWinnerResponse {
  endedMessage?: string;
  winnerBid?: {
    bid: number;
    bidPrice: number;
    id: number;
    timeCreated: string;
    drawNumber?: number;
    userAuction: {
      uniqueCode: number;
      ownershipPreEmption: boolean;
      preEmption: boolean;
    };
  };
}

export interface AuctionParameterResponse {
  key?: string;
  name: string;
  value: string;
}

export interface ServerTimeResponse {
  dateTime: {
    date: string;
    timezone_type: string;
    timezone: string;
  };
}

export interface NewsType {
  id: number;
  slug: string;
  title: string;
  perex: string;
  content: string;
  createdDt: string;
  updatedDt: string;
  deletedDt: string;
  publishDt: string;
  state: string;
  user: NewsUser;
  stateTranslation: string;
  newsMedia: AuctionImageType[];
}

export type NewsUser = {
  firstName: string;
  lastName: string;
};

export type NewsImageType = {
  id: number;
  media: NewsImageMediaType;
  position: number;
  title: string;
};

export interface NewsImageMediaType {
  enabled: boolean;
  hash: string;
  id: number;
  mimeType: string;
  module: string;
  originalName: string;
  originalPath: string;
  relativePath: string;
  type: string;
  thumbnailUrl?: string;
  embedHash?: string;
}

export interface AuctioneerPDData {
  id: string;
  login: string;
  password: string;
  hash: string;
}

export interface CSASAuthResponse {
  url: string;
}

export interface AuctionMessagesResponse {
  [key: string]: string;
}

export interface AuctionSendMessageValues {
  auctionMessageType: string;
  subject: string;
  content: string;
  auctionMessageBuyers?: { user: string }[];
  auctionMessageMedias?: { media: string }[];
}

export interface AuctioneerTemplateAuctioneer {
  id: number;
}

export interface AuctioneerTemplate {
  id: number;
  name: string;
  data: any;
  isDefault: boolean;
  auctionType: string;
  auctioneer: AuctioneerTemplateAuctioneer;
  timeCreated: string;
  timeUpdated: string;
  state: string;
  stateTranslation: string;
}

export interface Supplier {
  id: number;
  title: string;
  ico: string;
  dic: string;
  street: string;
  city: string;
  zip: string;
  bankAccount: string;
  iban: string;
  swift: string;
  bankTitle: string;
  email: string;
  phoneNumber: string;
}

export interface InvoicesFilters extends PaginationParams {
  paid?: boolean;
  invoiceNumber?: string;
  enforcement?: boolean;
  auctioneer?: string;
  auction?: string;
  auctioneerType?: string;
  dateOfIssueFrom?: string;
  dateOfIssueTo?: string;
  datePaidFrom?: string;
  datePaidTo?: string;
}

export interface InvoiceResponse {
  id: number;
  invoiceNumber: string;
  invoicedPrice: number;
  invoicedPriceVat: string;
  invoicedPriceWithVat: number;
  dateOfIssue: string;
  dateOfOfTaxableSupply: string;
  dateDue: string;
  due: number;
  paid: boolean;
  datePaid: string;
  enforcement: boolean;
  dateEnforcement: string;
  note: string;
  currency: string;
  auctionInvoiceSupplier: AuctionInvoiceSupplier;
  auctionInvoiceSubscriber: AuctionInvoiceSubscriber;
  auctionInvoiceItems: AuctionInvoiceItem[];
  timeCreated: string;
  auction?: {
    id: number;
    hash: string;
    state: string;
    auctionType: string;
  };
}

export interface PaymentResponse {
  id: string;
  paymentType: string;
  paymentTypeTranslation: string;
  transactionId: string;
  paymentOrderId: string;
  variableSymbol: string;
  variableSymbolOwn: string | null;
  constantSymbol: string | null;
  specificSymbol: string | null;
  amount: number;
  currency: string;
  currencyTranslation: string;
  date: string;
  senderAccountNumber: string;
  senderBankCode: string;
  senderBankName: string;
  senderName: string;
  userIdentity: string;
  userMessage: string | null;
  transactionType: string;
  performedBy: string | null;
  comment: string;
  specification: string | null;
  note: string | null;
  timeCreated: string;
  timeUpdated: string;
  createdUser: null;
}

export interface PaymentTypesResponse {
  type: string;
  translated: string;
}

export interface PaymentUpdateData {
  note: string;
  paymentType: string;
  variableSymbolOwn: string;
}

export interface PaymentsFilters extends PaginationParams {
  senderName?: string;
  paymentType?: string;
  variableSymbol?: string;
  senderAccountNumber?: string;
}

export interface AuctionInvoiceSupplier {
  title: string;
  ico: string;
  dic: string;
  street: string;
  city: string;
  zip: string;
  bankAccount: string;
  iban: string;
  swift: string;
  bankTitle: string;
  email: string;
  phoneNumber: string;
}

export interface AuctionInvoiceSubscriber {
  title: string;
  ico: string;
  dic: string;
  street: string;
  city: string;
  zip: string;
  bankAccount: string;
  bankTitle: string;
  email: string;
  phoneNumber: string;
}

export interface AuctionInvoiceItem {
  serviceType: string;
  serviceTypeTranslation: string;
  unitPrice: number;
  unitPriceWithVat: number;
  vat: string;
  items: number;
  unit: string;
  unitTranslation: string;
  sumPrice: number;
  sumPriceVat: number;
  sumPriceWithVat: number;
}

export interface QuantificationOfInvoicing {
  invoicePaid: string;
  invoiceNotPaid: string;
}

export interface InvoicePDFResponse {
  id: number;
  type: string;
  hash: string;
  originalPath: string;
  relativePath: string;
  module: string;
  mimeType: string;
  enabled: boolean;
  originalName: string;
  title: string;
}

export interface StatisticGraphEnumType {
  type: string;
  translated: string;
}

export interface StatisticYearsType {
  [key: string]: string;
}

export interface StatisticLabels {
  key: string;
  title: string;
}

export interface StatisticValues {
  key: string;
  value: number;
}

export interface StatisticData {
  month: string;
  values: [StatisticValues];
}

export interface StatisticReponse {
  graph: {
    title: string;
    unit: string;
  };
  labels: [StatisticLabels];
  data: [StatisticData];
}

export interface AuctionProtocolListResponse {
  id: string;
  type: string;
  auctionStatus: string;
  typeTranslation: string;
  timeCreated: string;
  auctionStatusTranslation: string;
  typeTranslations: string;
}

export interface AuctionInformedConsent {
  id?: number;
  uniqueId?: string;
  title: string;
  text: string;
  enabled?: boolean;
  approved?: boolean;
}

export interface ProtestAdminResponse {
  id: number;
  message: string;
  reason: string;
  status: string;
  processed: boolean;
  statusTranslation: string;
  userAuction: {
    user: {
      company?: string;
      degreePost?: string;
      degreePre?: string;
      firstName?: string;
      lastName?: string;
    };
  };
}

export interface FacebookStatusResponse extends ReactFacebookLoginInfo {
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  data_access_expiration_time: number;
}

export interface FacebookInfoResponse {
  expirationDt: string;
  pageId: string | null;
  instagramPageId: string | null;
  pageName: string | null;
}

export interface FacebookConnectData {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
}

export interface FacebookPageData {
  pageId: string;
}

export interface FacebookPageResponse {
  id: string;
  name: string;
}

export interface FacebookPagesResponse {
  [key: string]: FacebookPageResponse;
}

export interface AppStatistics {
  priciest: number;
  totalBuyers: number;
  totalAuctions: number;
  totalAuctioneers: number;
}

export interface AuctionBuyerListResponse {
  user: {
    stateTranslation: string;
    firstName: string;
    lastName: string;
    email: string;
    timeCreated: string;
    timeUpdated: string;
    createdUserId: string;
    createdUserFullName: string;
    id: string;
    degreePre: string;
    degreePost: string;
    phone: string;
    state: string;
    timeStateUpdate: string;
    contact: boolean;
    dataBox: string;
    accountNumber: string;
    birthdate: string;
    personalIdentificationNumber: string;
    identityCardNumber: string;
    nonDeclinedDocumentCount: number;
    userAddresses: {
      id: string;
      type: string;
      typeTranslated: string;
      address: string;
      city: string;
      zipCode: string;
      mainAddress: boolean;
    }[];
    share: string;
    company: string;
    ico: string;
    dic: string;
    role: string;
    verificationState: string;
    buyerType: string;
    buyerTypeTranslation?: string;
    subjectName: string;
    position: string;
  };
  auction: {
    timeCreated: string;
    timeUpdated: string;
    cautionDeposit: string;
    createdUserId: string;
    createdUserFullName: string;
    id: string;
    auctioneer: {
      timeCreated: string;
      timeUpdated: string;
      createdUserId: string;
      createdUserFullName: string;
      id: string;
      auctioneerName: string;
    };
    number: string;
    title: string;
  };
  uniqueCode: string;
  joinedTime: string;
  authorizationState: string;
  auctionSecurity: string;
  authorizationStateReason: string;
  securityStateReason: string;
  variableSymbol: string;
  specificSymbol: string;
  preEmption: boolean;
  admitted: boolean;
  twoHoursReminderSent: boolean;
  fortyEightHoursReminderSent: boolean;
  howToPayAuctionSecurityCounter: number;
  paid: boolean;
  datePaid: string;
  authorizationStateTranslation: string;
  auctionSecurityTranslation: string;
  canLogout: boolean;
  canDraw: boolean;
  hasActiveProtest: boolean;
  isWinner: boolean;
}

export interface AdminDocumentsUsersFilter extends PaginationSortingParams {
  name?: string;
  number?: string;
  buyerType?: string;
  status?: (string | null)[];
  mainCategory?: string;
  subCategory?: string;
  cautionState?: string;
  admitted?: boolean;
  startDtFrom?: string;
  startDtTo?: string;
  endDtFrom?: string;
  endDtTo?: string;
  auctioneer?: string;
  variableSymbol?: string;
  specificSymbol?: string;
  auctionSecurity?: string;
  authorizationState?: string;
}

export interface UserConsent {
  id: string;
  fullName: string;
  gdprConsent: boolean;
  gdprConsentDt: string;
  newsletter: boolean;
  newsletterDt: string;
  portalConsent: string;
  portalConsentDt: string;
}

export type TimesEnumType = {
  hours: string[];
  minutes: string[];
};

export type AuctionBackSecurityData = {
  backSecurity: boolean;
};

export type AuctionUpdateOutageData = {
  startDt: string;
  endDt: string;
};

export type OngoingAuctionUpdateOutageData = {
  endDt: string;
};

export interface AuctionEmailType {
  id: number;
  subject: string;
  timeCreated?: string;
  attachments: [UserEmailAttachment];
  user: {
    id: number;
    email: string;
    subjectName: string;
  };
}

export interface AuctionEmailsFilter extends PaginationSortingParams {
  content?: string;
  subject?: string;
  email?: string;
}

export interface UserEmailsFilter extends PaginationSortingParams {
  content?: string;
  subject?: string;
}
