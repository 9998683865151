import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';
import React, { FC, useState } from 'react';

import { arrKeyExists } from '@helpers/validator';
import { BaseType, AuctionParametersResponse } from '@types';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, FormGroup, Select } from '@components';

import { BooleanParameter } from './BooleanParameter';
import { AuctionParameterForm } from './AuctionParameterForm';

interface SelectOptionType {
  value: string;
  label: string;
  mandatoryHouseNumber?: boolean;
  mandatoryRuian?: boolean;
}

export const RealEstateOtherForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [otherCategories, setOtherCategories] = useState<AuctionParametersResponse[]>([]);
  const [otherObjectTypes, setOtherObjectTypes] = useState<AuctionParametersResponse[]>([]);
  const [otherLocationTypes, setOtherLocationTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingTypes, setHeatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);

  const categoriesOptions = otherCategories.map((item) => ({
    value: item.id,
    label: item.title,
    mandatoryRuian: item.mandatoryRuian,
    mandatoryHouseNumber: item.mandatoryHouseNumber,
  }));
  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingTypeOptions = heatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectTypeOptions = otherObjectTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectLocationOptions = otherLocationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  React.useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const otherObjectTypesResponse = await auctionParameterApi.getRealEstateOtherObjectType();
      setOtherObjectTypes(otherObjectTypesResponse.data.data);

      const otherLocationTypesResponse = await auctionParameterApi.getRealEstateOtherLocationType();
      setOtherLocationTypes(otherLocationTypesResponse.data.data);

      const categoriesResponse = await auctionParameterApi.getRealEstateOtherCategories();
      setOtherCategories(categoriesResponse.data.data);

      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingTypes(realEstateRes.data.data.parameterRealEstateHeating);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleOtherObjectTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateOtherObjectType', itemValue?.value);
  };

  const handleOtherLocationTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateOtherLocationType', itemValue?.value);
  };

  const handlePlacementChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleOtherBuildingConstructionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateBuildingConstruction', itemValue?.value);
  };

  const handleOtherObjectStatusChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRealEstateOtherHeatingChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherHeatings || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherHeatings',
      itemValue.map((i) => ({
        parameterRealEstateHeating: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeating.id || f.parameterRealEstateHeating) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWaterChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherWastes',
      itemValue.map((i) => ({
        parameterRealEstateWaste: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      }))
    );
  };

  const handleOtherParkingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateParking', itemValue?.value);
  };

  const handleTransportChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleFurnishedChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateFurnished', itemValue?.value);
  };

  const handleProtectionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateEnergyEfficiencyRating', itemValue?.value);
  };

  const handleTelecommucationChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateOther?.auctionParameterRealEstateOtherTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateOther.auctionParameterRealEstateOtherTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const handleChangeCategory = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateOther.parameterRealEstateOtherCategory', itemValue?.value);
    props.setFieldValue('mandatoryRuian', itemValue?.mandatoryRuian ?? true);
    props.setFieldValue('mandatoryHouseNumber', itemValue?.mandatoryHouseNumber ?? true);
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Kategorie{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateOtherCategory') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateOtherCategory">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateOtherCategory"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherCategory}
              options={categoriesOptions}
              onChange={handleChangeCategory}
              value={
                categoriesOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateOtherCategory)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherCategory && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherCategory}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Budova{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateBuildingConstruction') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleOtherBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateBuildingConstruction)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateBuildingConstruction}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stav{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateObjectStatus') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleOtherObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateObjectStatus)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateObjectStatus}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ stavby{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateOtherObjectType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateOtherObjectType">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateOtherObjectType"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherObjectType}
              options={objectTypeOptions}
              onChange={handleOtherObjectTypeChange}
              value={
                objectTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateOtherObjectType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherObjectType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherObjectType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Poloha objektu
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateOtherLocationType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateOtherLocationType">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateOtherLocationType"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherLocationType}
              options={objectLocationOptions}
              onChange={handleOtherLocationTypeChange}
              value={
                objectLocationOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateOtherLocationType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherLocationType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateOtherLocationType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="number"
        label="Počet podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateOther_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateOther.numberOfFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'numberOfFloors')}
        value={props.values.auctionParameterRealEstateOther.numberOfFloors}
        dataTestId="auctionParameterRealEstateOther.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateOther_undergroundFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateOther.undergroundFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'undergroundFloors')}
        value={props.values.auctionParameterRealEstateOther.undergroundFloors}
        dataTestId="auctionParameterRealEstateOther.undergroundFloors"
      />

      <FormGroup
        type="number"
        label="Umístění podlaží"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateOther.floorNumber"
        required={arrKeyExists(props.requiredParametersFields, 'floorNumber')}
        value={props.values.auctionParameterRealEstateOther.floorNumber}
        error={props.errors.auctionParameterRealEstateOther_floorNumber as string}
        onChange={props.handleChange}
        dataTestId="auctionParameterRealEstateOther.floorNumber"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <BooleanParameter
        label="Výtah"
        dataTestId="auctionParameterRealEstateOther.elevator"
        name="auctionParameterRealEstateOther.elevator"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateOther?.elevator}
        errors={props.errors.auctionParameterRealEstateOther_elevator}
        required={arrKeyExists(props.requiredParametersFields, 'elevator')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateOther.elevator', val)}
      />

      <BooleanParameter
        label="Bezbariérový přístup"
        dataTestId="auctionParameterRealEstateOther.easyAccess"
        name="auctionParameterRealEstateOther.easyAccess"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateOther?.easyAccess}
        errors={props.errors.auctionParameterRealEstateOther_easyAccess}
        required={arrKeyExists(props.requiredParametersFields, 'easyAccess')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateOther.easyAccess', val)}
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Umístění{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePlacement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstatePlacement}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Okolní zástavba
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateSurroundingsType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateSurroundingsType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateSurroundingsType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ochrana{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateProtection') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateProtection)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateProtection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateOther.usableArea"
          thousandSeparator
          label="Plocha užitná"
          error={props.errors.auctionParameterRealEstateOther_usableArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'usableArea')}
          value={props.values.auctionParameterRealEstateOther.usableArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateOther.usableArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateOther.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateOther.builtUpArea"
          thousandSeparator
          label="Plocha zastavěná"
          error={props.errors.auctionParameterRealEstateOther_builtUpArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'builtUpArea')}
          value={props.values.auctionParameterRealEstateOther.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateOther.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateOther.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateOther.gardenArea"
          error={props.errors.auctionParameterRealEstateOther_gardenArea as string}
          thousandSeparator
          label="Plocha zahrady"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'gardenArea')}
          value={props.values.auctionParameterRealEstateOther.gardenArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateOther.gardenArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateOther.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateOther.floorArea"
          error={props.errors.auctionParameterRealEstateOther_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'floorArea')}
          value={props.values.auctionParameterRealEstateOther.floorArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateOther.floorArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateOther.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <BooleanParameter
        label="Parkování"
        dataTestId="auctionParameterRealEstateOther.parkingExists"
        name="auctionParameterRealEstateOther.parkingExists"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateOther?.parkingExists}
        errors={props.errors.auctionParameterRealEstateOther_parkingExists}
        required={arrKeyExists(props.requiredParametersFields, 'parkingExists')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateOther.parkingExists', val)}
      />

      {props.values.auctionParameterRealEstateOther.parkingExists === 'yes' && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateParking">
              <Select
                isClearable
                size="md"
                isDisabled={props.readOnly}
                name="parameterRealEstateParking"
                isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateParking}
                options={parkingTypeOptions}
                onChange={handleOtherParkingChange}
                value={
                  parkingTypeOptions.find(
                    (i) =>
                      i.value ===
                      getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateParking)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateParking && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateOther_parameterRealEstateParking}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Třída energetické náročnosti
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateEnergyEfficiencyRating') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateOther.parameterRealEstateEnergyEfficiencyRating
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateEnergyEfficiencyRating}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj vody{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherWaters') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWater">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWater"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherWaters || []).map(
                (v: { parameterRealEstateWater: string | number }) =>
                  waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWaters}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod topení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherHeatings') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeating">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateHeating"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherHeatings}
              options={heatingTypeOptions}
              onChange={(val: BaseType) => handleRealEstateOtherHeatingChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherHeatings || []).map(
                (v: { parameterRealEstateHeating: string | number }) =>
                  heatingTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeating))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherHeatings && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherHeatings}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod plynu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherGases') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateOtherGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateOtherGases"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherGases || []).map(
                (v: { parameterRealEstateGas: string | number }) =>
                  gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherGases}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ odpadu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherWastes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaste">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWaste"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherWastes || []).map(
                (v: { parameterRealEstateWaste: string | number }) =>
                  wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherWastes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Příjezdová komunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherRoadTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateRoadType"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherRoadTypes}
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherRoadTypes || []).map(
                (v: { parameterRealEstateRoadType: string | number }) =>
                  roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherRoadTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherRoadTypes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Telekomunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherTelecommunications')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateOtherTelecommunications">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateOtherTelecommunications"
              isInvalid={
                !!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTelecommunications
              }
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTelecommunications && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTelecommunications}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Elektřina
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherElectricities') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateElectricity">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateElectricity"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherElectricities}
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherElectricities || []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherElectricities}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Doprava{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateOtherTransports') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateOtherTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateOtherTransports"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTransports}
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(props.values.auctionParameterRealEstateOther.auctionParameterRealEstateOtherTransports || []).map(
                (v: { parameterRealEstateTransport: string | number }) =>
                  transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_auctionParameterRealEstateOtherTransports}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateOther_costOfLiving as string}
        name="auctionParameterRealEstateOther.costOfLiving"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'costOfLiving')}
        value={props.values.auctionParameterRealEstateOther.costOfLiving}
        dataTestId="auctionParameterRealEstateOther.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zařízeno{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFurnished') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateOther_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateOther.parameterRealEstateFurnished)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateOther_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateOther_parameterRealEstateFurnished}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
